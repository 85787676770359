<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div
            class="d-flex justify-content-between align-items-center px-1 py-50"
          >
            <div class="col-3">
              <h3 class="m-0">{{ $t("Clientes") }}</h3>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <b-link :to="{ name: 'createClient' }">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 text-nowrap"
                  >
                    {{ $t("Crear nuevo") }}
                  </b-button>
                </b-link>
              </div>
              <!--   <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  type="text"
                  class="d-inline-block"
                  :placeholder="$t('Busqueda')"
                  @input="handleSearch"
                />
              </b-input-group> -->
              <b-form-group :label="$t('Busqueda')" label-for="filter-search">
                <b-row>
                  <b-col sm="8">
                    <b-form-input
                      v-model="searchTerm"
                      :placeholder="$t('Busqueda')"
                      type="text"
                      class="d-inline-block"
                    />
                  </b-col>
                  <b-col sm="4" style="padding-left: 0px">
                    <b-button @click="handleSearch" variant="primary">
                      {{ $t("Buscar") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </div>
          <b-table hover responsive :items="items" :fields="columns">
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <div
                  :style="`background-image: url('${data.item.image.path}');`"
                  class="d-inline-block img-product-list mr-50"
                ></div>
                <p class="d-inline-block m-0">{{ data.item.name }}</p>
              </div>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewClient', params: { id: data.item.id } }"
                  class="mr-1"
                >
                  <feather-icon icon="EyeIcon" size="16" />
                </b-link>
                <b-link
                  :to="{ name: 'editClient', params: { id: data.item.id } }"
                  class="mr-1"
                >
                  <feather-icon icon="Edit2Icon" size="16" />
                </b-link>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="ml-50 mr-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{ $t("Total") }}: {{ totalItems }}</span
            >
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BFormGroup,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BLink,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default {
  components: {
    BFormGroup,
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "15", "25"],
      columns: [
        {
          label: this.$t("NombreCliente"),
          key: "name",
        },
        {
          label: this.$t("NActivoAptos"),
          key: "total_aptos",
        },
        {
          label: this.$t("NActivoNoAptos"),
          key: "total_no_aptos",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-right",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      items: "clients/getItems",
      totalItems: "clients/getTotalItems",
    }),
  },
  created() {
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "clients/getListClients",
    }),
    chargeData() {
      this.list({
        company: this.currentCompany.id,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
  },
};
</script>
